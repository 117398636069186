import React, { useState, useEffect } from 'react';
import moment from 'moment'
import MaterialTable from '@material-table/core';
import { firestore } from '../../../firebase/firebase';
import { useMediaQuery } from '@material-ui/core';
import { TableIcons } from '../../../constants/tableIcons';
//not used, users should be loaded by now from reporting component
import { makeStyles } from '@material-ui/core';
import { useUser } from '../../../providers/UserContext';
import { useTheme } from '@material-ui/core/styles';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { withRouter} from 'react-router-dom';
import _ from 'lodash';
const useStyles = makeStyles((theme) => ({
    content: {
        width: '100%'
    },
}));

const UserWorkOrdersTable = (props) => {
    const { getUsers, users } = useUser();
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    useEffect(() => {
    }, [smallScreen]);
    const [workOrderData, setWorkOrderData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [userData] = useState(props.userData || [])
    const [searchBarValue, setSearchBarValue] = useState(userData.displayName+"SearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem(userData.displayName+"SearchBarValue")) : null)
    const [page, setPage] = useState(userData.displayName+"PageValue" in sessionStorage ? JSON.parse(sessionStorage.getItem(userData.displayName+"PageValue")) : 0)

    const fetchWorkOrders = () => {
        // First query: where assignedUser is equal to userData.uuid
        const query1 = firestore.collection('workOrders')
            .where('assignedUser', '==', userData.uuid)
            .orderBy('dateCreated');
    
        // Second query: where userData.uuid is in the secondaryTechs array
        const query2 = firestore.collection('workOrders')
            .where('secondaryTechs', 'array-contains', userData.uuid)
            .orderBy('dateCreated');
    
        // Perform both queries and combine the results
        let unsubscribe1 = query1.onSnapshot(querySnapshot1 => {
            let workOrders1 = querySnapshot1.docs.map((doc) => ({
                ...doc.data(),
                'docId': doc.id,
            }));
    
            // Second query
            let unsubscribe2 = query2.onSnapshot(querySnapshot2 => {
                let workOrders2 = querySnapshot2.docs.map((doc) => ({
                    ...doc.data(),
                    'docId': doc.id,
                }));
    
                // Combine both results, removing duplicates if necessary
                let combinedWorkOrders = [...workOrders1, ...workOrders2];
    
                // Sort by date (newest to oldest)
                setWorkOrderData(
                    combinedWorkOrders.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated))
                );
                setLoading(false);
                setPage(userData.displayName + "PageValue" in sessionStorage 
                    ? JSON.parse(sessionStorage.getItem(userData.displayName + "PageValue")) 
                    : 0);
            });
    
            return () => {
                unsubscribe1();
                unsubscribe2();
            };
        });
    };

    useEffect(() => {
        //only call get users once
        if(users.length <= 0){
           getUsers();
        }
        fetchWorkOrders();
        
    }, [getUsers, userData,]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleOnSearchChange = (value) => {
        setSearchBarValue(value);  
        sessionStorage.setItem(userData.displayName+"SearchBarValue", JSON.stringify(value));
    
        setPage(0);  
        sessionStorage.setItem(userData.displayName+"PageValue", JSON.stringify(0));
      }
    
    const handleOnPageChange = (pageNum) => {
        setPage(pageNum);     
        sessionStorage.setItem(userData.displayName+"PageValue", JSON.stringify(pageNum));
        
    }
    const timesheetClickHandler = (path) => {
        props.history.push({
            pathname: '/dashboard/workOrders/form',
            search: "?" + new URLSearchParams({ docId: path }).toString(),

        })
    }

    const getUserDisplayName = (uuid) => {
        let user = _.find(users, {uuid: uuid});
        if (!_.isUndefined(user)) {
            return user.displayName;
        } else {
            return uuid;
        }
    }
    
    return (
        <div className={classes.content}>
            {!loading && workOrderData.length === 0 ? <div>No Work Orders Found</div> :
            <MaterialTable

            columns={[
                { title: 'Unit #', width:'10%', field: 'vehicle.unitNumber' },
                { title: 'Customer', field: 'customer.companyName' },
                { title: 'Contact', field: 'customer.contactName' },
                
                { title: 'Date Created', field: 'dateCreated', type: 'date', render: rowData => {
                    return moment(rowData.dateCreated).format('MM/DD/YYYY')
                }},
                { title: 'Tech(s)', field: 'assignedUser', render: rowData => {
                  return rowData.assignedUser === '' ? 'Unassigned' : //combine all techs into one string
                  rowData?.secondaryTechs && rowData?.secondaryTechs.length > 0 ? getUserDisplayName(rowData.assignedUser) + '\n' + rowData?.secondaryTechs.map((tech) => getUserDisplayName(tech)).join('\n') : getUserDisplayName(rowData.assignedUser) 
                }},
                { title: 'Repairs Needed', width:'1%', type:'numeric', render: rowData => {
                  return <b>{rowData.repairsNeeded.length}</b>
                }},
                { title: 'Repairs Completed', width:'1%', type:'numeric', render: rowData => {
                    return <b>{rowData.repairsNeeded.filter(x=> x.completed).length}</b>
                  }},
              ]}
                data={workOrderData}
                title={`${userData.displayName.substring(0, userData.displayName.indexOf(" "))}'s Work Orders`}
                isLoading={loading}
                icons={TableIcons}
                actions={[
                    {
                        icon: OpenInNewIcon,
                        tooltip: 'View Work Order',
                        onClick: (event, rowData) => timesheetClickHandler(rowData['docId']),
                    },
                ]}
                onSearchChange={ (value) => {handleOnSearchChange(value) }}
                onPageChange={ (pageNum) => {handleOnPageChange(pageNum) }}
                options={{
                    toolbar: true,
                    paging: true,
                    initialPage: page,
                    pageSizeOptions: false,
                    pageSize: 12,
                    actionsColumnIndex: -1,
                    search: true,
                    emptyRowsWhenPaging: false,
                    thirdSortClick: false,
                    searchText: searchBarValue,
                    rowStyle: rowData => {
                        if ((rowData.completed)) {
                            return { backgroundColor: 'lightGreen', whiteSpace: 'pre-wrap', };
                        }
                        return {whiteSpace: 'pre-wrap', };
                    }
                }}
            />}
        </div>
    )
}
export default withRouter(UserWorkOrdersTable)