import React, {Suspense, lazy} from 'react';
import { AuthProvider } from './providers/AuthContext'
import ScrollToTop from '../src/Public/components/UI/ScrollToTop'
import { amber, grey } from '@material-ui/core/colors';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import MomentUtils from '@date-io/moment';
import { useThemes } from './providers/ThemesContext';
import Meta from './providers/Meta';
import "@fontsource/roboto"

import Application from './Admin/containers/Application';

//import {ReactTable} from './Admin/components/Users/Users';
const themeX = createTheme({

  //Prevent google api from overriding typography fonts
  typography: {
    "fontFamily": `Roboto`,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
  },

  palette: {
    primary: {
      main: amber[500],
    },
    secondary: {
      main: '#444',
    },
    background: {
      default: '#ddd',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        color: '#000000',
      },
      contained: {
        backgroundColor: '#ffc107',
        '&:hover': {
          backgroundColor: '#ffc107',
        },
      },
      outlined: {
        '&:focused': {
          color: amber[500],
        },
      }
    },
    MuiOutlinedInput: {
      root: {
        '&:hover $notchedOutline': {
          borderColor: grey[200]
        },
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          '&:hover $notchedOutline': {
            borderColor: grey[200]
          }
        },
        '&$focused $notchedOutline': {
          borderColor: grey[400],
        },
      },
      notchedOutline: {
        borderColor: grey[400],
      },

    },
    MuiFormLabel: {
      root: {
        color: '#000000',
      }
    },


    MuiInputBase: {
      input: {
        color: '#000000',
      }

    }
  }
});
const darkTheme = createTheme({

  //Prevent google api from overriding typography fonts
  typography: {
    "fontFamily": `Roboto`,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
  },

  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      main: amber[500],
    },
    background: {
      default: '#171717'
    },
  },
 
  overrides: {
    MuiTextField : {
      root: {
        color:amber[500],
        backgroundColor: '#222',
      },
    },
    MTableHeader: {
 

      header: {
        color:amber[500],
        backgroundColor: '#222'
      },

    },
    MuiTableCell: {
      root: {
        color:amber[500],
        backgroundColor: '#222',
        borderBottom: '1px solid ' + amber[500],
      },

    },
    MuiChip: {
      root: {
        color:amber[500]
      },
    },
    MuiContainer: {
      root: {
        color:amber[500]
      },
    },
    MuiInputAdornment: {
      root: {
        color:amber[500]
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor:'#111'
      }
    },
    MuiIconButton: {
      label: {
        color:amber[500]
      },

    },
    MuiListItem: {
      root: {
        color:amber[500]
      },
    },
    MuiListSubheader:  {
      root: {
        color:amber[500]
      },
    },
    MuiListItemIcon: {
      root: {
        color:amber[500]
      },
    },
    MuiPaper: {
      root: {
        backgroundColor:'#222',
        color: amber[500],
      },
    },
    MuiTypography: {
      root: {
        color:amber[500]
      },
      body1: {
        color:amber[500]
      },
      h4: {
        color:amber[500]
      },
      colorTextSecondary: {
        color: amber[500]
      },
    },
    MuiButton: {
      root: {
        color: amber[500],
      },
      contained: {
        backgroundColor: '#000',
        color: amber[500],
        '&:hover': {
          backgroundColor: '#111',
        },
      },
      outlined: {
        borderColor: amber[500],
        '&:focused': {
          color: amber[500],
        },
      }
    },
    MuiFormControlLabel: {
      root: {
        color: '#fff',
      },
      label: {
        color: '#fff',
      },
    },
    MuiSkeleton: {
      root: {
        backgroundColor:'#444'
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover $notchedOutline': {
          borderColor: grey[50]
        },
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          '&:hover $notchedOutline': {
            borderColor: grey[50]
          }
        },
        '&$focused $notchedOutline': {
          borderColor: grey[50],
        },
      },
      notchedOutline: {
        borderColor: grey[50],
      },

    },
    MuiFormLabel: {
      root: {
        color: amber[500],
      }
    },
    MuiInputBase: {
      input: {
        color: amber[500],
        borderColor: amber[500],
      }

    },

  }
});
const App = () => {
  const { colorMode, setColorMode } = useThemes();

  React.useEffect(() => {}, [colorMode]) // eslint-disable-line react-hooks/exhaustive-deps

  //const Application = lazy(() => import('./Admin/containers/Application'));
  const Public = lazy(() => import('./Public/containers/Public/Public'));
  
  //called here and passed into public (class component that cant use hooks)
  const location = useLocation();

  return (
    <MuiThemeProvider theme={(location.pathname.substring(1, 10) !== 'dashboard' || colorMode === 'light') ? themeX : darkTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router>
          <AuthProvider>
            <Meta/>
            {/*<ScrollToTop> loads <ProductMain> at page start and not the scroll location saved in Router*/}
            <ScrollToTop />
            <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              {/* "/" has to be last as it will match every route. */}
             
              <Route path="/dashboard"> <Application colorMode={colorMode} setColorMode={setColorMode} /></Route>

              <Route path="/" component={Public} location={location}/>

            </Switch>
            </Suspense>
          </AuthProvider>
        </Router>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>

  
  );
}

export default App;
